import React from 'react';
import { PropsWithClassProps, Link } from '@vgn-medien-holding/vgn-fe-components';
import { twMerge } from 'tailwind-merge';
import { Genre as GenreType } from '@lib/graphql/generated';

export interface GenreProps extends PropsWithClassProps {
  genre: GenreType;
  type?: 'small' | 'compact' | 'medium' | 'mediumText';
}

export const Genre = ({ genre, type = 'compact', classProps }: GenreProps) => {
  const style = {
    small:
      'rounded-lg inline-block border-0.5 px-2 py-1 text-gray-500 border-gray-650/25 leading-body tracking-widest text-2xs hyphenate',
    compact: 'rounded-lg inline-block border p-2 text-gray-400 border-gray-650/25 bg-gray-650/25 label-sm hyphenate',
    medium:
      'grid aspect-square border-0.5 border-primary/0 cursor-pointer hover:border-primary leading-header transition-colors duration-150 min-h-36 min-w-36 items-center justify-center rounded-lg hyphenate streaming-entry-bg p-2 text-center text-xs font-bold uppercase tracking-2px text-white',
    mediumText: 'leading-header hyphenate text-center text-xs font-bold uppercase tracking-2px text-white',
  };
  const genreStyle = twMerge(style[type], classProps?.root);
  return (
    <Link
      href={'/genres/' + genre.slug}
      noLink={['compact', 'mediumText'].includes(type)}
      classProps={{ root: genreStyle }}
    >
      {genre.title}
    </Link>
  );
};
